import axios from "axios";

export const API_ENDPOINT = "https://masterslaundry.zerame.com/api/";

// export const API_ENDPOINT = "https://app.masterslaundry.co.in/api/";

const axiosInstance = axios as any;

export async function callApi(method: string, path: string, data?: any) {
  const authKey = localStorage.getItem("authkey");

  const headers = {
    Authorization: `Bearer ${authKey}`,
    "Content-Type": "application/json",
    Origin: window.location.origin,
  };

  try {
    if (method.toUpperCase() === "POST") {
      return await axios.post(API_ENDPOINT + path, data, { headers });
    } else if (method.toUpperCase() === "DELETE") {
      return await axios.delete(API_ENDPOINT + path, { headers });
    } else {
      return await axios.get(API_ENDPOINT + path, { headers });
    }
  } catch (error: any) {
    console.error("API call error:", error);
    if (axiosInstance.isAxiosError(error) && error.response?.status === 401) {
      localStorage.removeItem("authkey");
      localStorage.removeItem("role");
      localStorage.removeItem("name");
      window.location.href = "/";
    }
    throw error;
  }
}
