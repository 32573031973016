import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Badge } from 'react-bootstrap';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";
import { log } from 'node:console';

const TagUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const [loading, setLoading] = useState(false);

  const [apartments, setApartment] = useState([]);

  const [formData, setFormData] = useState<{
    id: string;
    tagName: string;

  }>({
    id: '',
    tagName: '',

  });
  useEffect(() => {
    if (user) {
      getProduct();
    }
    getService();
    console.log(formData)
  }, []);

  const getService = () => {
    callApi("GET", "apartmentDropdown.php")
      .then((res) => res.data)
      .then((response: any) => {
        if (response) {
          setApartment(response["data"]);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const getProduct = () => {
    let req = {
      id: user
    };
    callApi("POST", "tagSingleSelect.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response) {
          
          setFormData({
            id: response.id,
            tagName: response.tagName,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addProduct = () => {
    setLoading(true);
    const trimmedtagName = formData.tagName.trim();
    if (trimmedtagName) {
      let req = {
        tagName: trimmedtagName,
      };
      callApi("POST", "tagInsert.php", req)
        .then((res) => res.data)
        .then((response: any) => {
          console.log("response", response);
          if (response["success"] === true) {
            showSuccess("Success!", "Tag created successfully!");
            setTimeout(() => {
              navigate("/layout/tag-management");
            }, 1000);
            setLoading(false);
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      showError("Failed", "Empty Space Not Allowed");
    }
  };

  const updateProduct = () => {
    setLoading(true);
    let req = {
      id: formData.id,
      tagName: formData.tagName,
    };
    callApi("POST", "tagUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Tag updated successfully!");
          setTimeout(() => {
            navigate("/layout/tag-management");
          }, 1000);
          setLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (user) {
      updateProduct();
    } else {
      addProduct();
      console.log(formData)
    }
  };


  return (
    <div style={{ margin: '2% 4% 4%' }}>
      <h5>{user ? 'Edit Product' : 'Add New Product'}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formProductName" style={{ marginTop: '1%' }}>
          <Form.Label>Tag Name<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="tagName"
            value={formData.tagName}
            onChange={handleChange}
            minLength={2}
            maxLength={60}
            required
          />
        </Form.Group>

        <Button style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', marginTop: '3%' }} type="submit" disabled={loading}>
          {user ? 'Update Tag' : 'Add Tag'}
        </Button>
      </Form>
    </div>
  );
};

export default TagUpdate;
