import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { CustomProvider, Container } from "rsuite";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "rsuite/dist/rsuite.min.css";
import Layout from "./Layout";
import Dashboard from "./components/pages/Dashboard";
import AddLead from "./components/pages/AddLead";
import StatusFormPage from "./components/pages/StatusFormPage";
import CustomerList from "./components/pages/CustomerList";
import CustomerInfo from "./components/pages/CustomerInfo";
import UserManagement from "./components/pages/UserManagement";
import UserUpdate from "./components/pages/UserUpdate";
import Login from "./components/login/Login";
import ApartmentManagement from "./components/pages/ApartmentManagement";
import ApartmentUpdate from "./components/pages/ApartmentUpdate";
import ServiceManagement from "./components/pages/ServiceManagement";
import ServiceUpdate from "./components/pages/ServiceUpdate";
import PaymentManagement from "./components/pages/PaymentManagement";
import PaymentUpdate from "./components/pages/PaymentUpdate";
import ProductManagement from "./components/pages/ProductManagement";
import ProductUpdate from "./components/pages/ProductUpdate";
import CollectionReport from "./components/pages/CollectionReport";
import PaymentDeliverReport from "./components/pages/PaymentDeliverReport";
import IroningCountReport from "./components/pages/IroningCountReport";
import TagManagement from "./components/pages/TagManagement";
import TagUpdate from "./components/pages/TagUpdate";

function App() {
  const navigate = useNavigate();

  const authKey = localStorage.getItem("authkey");

  useEffect(() => {
    if (authKey) {
      navigate("/layout/dashboard");
    }
  }, []);

  return (
    <CustomProvider theme="light">
      <Container className="app">
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/layout/*" element={<Layout />}>
              <Route index path="dashboard" element={<Dashboard />} />
              <Route path="orders/:status" element={<AddLead />} />
              <Route path="status-change" element={<StatusFormPage />} />
              <Route path="customer-list" element={<CustomerList />} />
              <Route path="customer-info/:id" element={<CustomerInfo />} />
              <Route path="user-management" element={<UserManagement />} />
              <Route path="apartment-management" element={<ApartmentManagement />} />
              <Route path="service-management" element={<ServiceManagement />} />
              <Route path="payment-management" element={<PaymentManagement />} />
              <Route path="product-management" element={<ProductManagement />} />
              <Route path="tag-management" element={<TagManagement />} />
              <Route path="tag-update" element={<TagUpdate />} />
              <Route path="user-update" element={<UserUpdate />} />
              <Route path="apartment-update" element={<ApartmentUpdate />} />
              <Route path="service-update" element={<ServiceUpdate />} />
              <Route path="payment-update" element={<PaymentUpdate />} />
              <Route path="product-update" element={<ProductUpdate />} />
              <Route path="collection-report" element={<CollectionReport />} />
              <Route path="payment-deliver-report" element={<PaymentDeliverReport />} />
              <Route path="ironing-count-report" element={<IroningCountReport />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Container>
    </CustomProvider>
  );
}

export default function AppWithRouter() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
