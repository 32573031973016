import React, { useEffect, useState } from "react";
import "./StatusFormPage.css";
import {  DatePicker, Modal } from 'rsuite';
import { useLocation, useNavigate } from 'react-router-dom';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";
import { Row, Col, InputGroup, Form, Badge ,Button,} from "react-bootstrap";
import RemoveItem from "../../assets/removeItem.svg";
import EditDesignIcon from "../../assets/header/addNew.svg";
import { GenerateInvoicePDF } from "./PdfGenerator";
import { log } from "console";

interface Order {
  productName: string;
  quantity: string;
  price: string;
  orderid: string;
}

interface LeadData {
  id: string;
  pickupDate: string;
  formPickupDate:string;
  apartmentName: string;
  apartmentId: string;
  flatNo: string;
  mobileNo: string;
  status: string;
  service: string;
  expectedDeliveryDate: string;
  assignedDeliveryPerson: string;
  deliveryPersonId: string;
  assignedIroningPerson: string;
  ironPersonId: string;
  category: string;
  comment: string;
  orderStatus: string;
  orders: Order[];
  totalPrice: string;
  totalItem: string;
  paymentMethod: string;
  receivedAmount: string;
  customerId: string;
  notInhomeStatus:string;
  selectedTags: string[]; 
  PaymentTempleteId:string;
  
}

interface Product {
  id: number;
  productName: string;
  price: number;
}

const StatusFormPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lead } = location.state || {};

  const [formData, setFormData] = useState<LeadData>({
    id: lead.id || "",
    pickupDate: lead.pickupDate || "",
    formPickupDate:lead.formPickupDate || "",
    apartmentName: lead.apartmentName || "",
    flatNo: lead.flatNo || "",
    mobileNo: lead.mobileNo || "",
    status: lead.status || "",
    service: lead.service || "",
    expectedDeliveryDate: lead.expectedDeliveryDate || "",
    assignedDeliveryPerson: lead.assignedDeliveryPerson || "",
    deliveryPersonId: lead.deliveryPersonId || "",
    assignedIroningPerson: lead.assignedIroningPerson || "",
    ironPersonId: lead.ironPersonId || "",
    category: lead.category || "",
    comment: lead.comments || "",
    orderStatus: lead.orderStatus || "",
    orders: lead.orders || [],
    totalPrice: lead.totalPrice || "0",
    totalItem: lead.totalItem || "0",
    paymentMethod: lead.paymentMethod || "",
    receivedAmount: lead.receivedAmount || "0",
    apartmentId: lead.apartmentId || "",
    customerId: lead.customerId || "",
    notInhomeStatus:lead.notInhomeStatus || "",
    selectedTags: lead.items?.map((item: any) => item.tagId) || [],
    PaymentTempleteId:"",
  });

  useEffect(() => {
    console.log("LeadData--->LeadData", formData);
    console.log("formData.status==>", formData.status);
    console.log("lead?.status==>", lead?.status);
    console.log("formData-orderStatus---?>", formData.orderStatus);
  }, [formData]);

  const [deliveryPerson, setDeliveryPerson] = useState<{ id: string, name: string }[]>([]);
  const [IronPerson, setIronPerson] = useState<{ id: string, name: string }[]>([]);
  const [itemFiles, setItemFiles] = useState<{ attachmentId: string; deleteAttachementStatus: false, imageType: string; imageData: string; }[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [orders, setOrders] = useState([
    { orderid: '', productName: '', price: '', quantity: '' }
  ]);
  const [service, setService] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImagePreview, setSelectedImagePreview] = useState('');
  const [products, setProducts] = useState<Product[]>([]);
  const [payment, setPayment] = useState([]);
  const [pdfLink, setPdfLink] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');
  const [showReceivedAmount, setShowReceivedAmount] = useState(false);
  const [roleType, setRoleType] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingupdate, setLoadingUpdate] = useState(false);
  const [loadingNotIn,setLoadingNotIn]= useState(false);
  const [tagsList, setTags] = useState([]);

  useEffect(() => {
      getTagsSelect();
    }, []);
    const getTagsSelect = () => {
      callApi("GET", "tagDropdown.php")
        .then((res) => res.data)
        .then((response: any) => {
          if (response) {
            setTags(response["data"]);
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const addTags = (apId: string) => {
      if (apId && !formData.selectedTags.includes(apId)) {
        setFormData((prev) => ({
          ...prev,
          selectedTags: [...prev.selectedTags, apId],
        }));
        console.log("selectedTags===>", formData.selectedTags);
      }
    };
  
    const removeTags = (apId: any) => {
      setFormData(prev => ({
        ...prev,
        selectedTags: prev.selectedTags.filter(id => id !== apId),
      }));
    };

  useEffect(() => {
    const role: any = localStorage.getItem('role');
    console.log("admin ===>role==", role);

    setRoleType(role);
  }, [roleType]);

  // const handleFileChange = (event: any) => {
  //   const files = Array.from(event.target.files);
  //   if (files.length > 0) {
  //     const newIcons: any[] = [];

  //     files.forEach((file: any) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);

  //       reader.onloadend = () => {
  //         const base64String = reader.result as string;
  //         const imageType = file.type.split("/")[1];
  //         console.log(base64String, imageType)
  //         const newIcon = {
  //           attachmentId: '',
  //           deleteAttachementStatus: false,
  //           imageType: imageType,
  //           imageData: base64String
  //         };
  //         newIcons.push(newIcon);
  //         if (newIcons.length === files.length) {
  //           setItemFiles((prevItemFiles: any) => {
  //             const updatedItemFiles = Array.isArray(prevItemFiles) ? prevItemFiles : [];
  //             return [...updatedItemFiles, ...newIcons];
  //           });
  //           setPreviewImages((prevPreviewImages) => {
  //             const updatedPreviewImages = Array.isArray(prevPreviewImages) ? prevPreviewImages : [];
  //             return [...updatedPreviewImages, ...newIcons.map(icon => icon.imageData)];
  //           });
  //         }
  //       };
  //     });
  //   }
  // };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length === 0) return;

    const newIcons: any[] = [];
    let processedCount = 0; // Track all files processed, success or failure

    const MAX_WIDTH = 800;  // Maximum dimensions for resizing
    const MAX_HEIGHT = 600;

    files.forEach((file: File) => {
      console.log("working--->");

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        processedCount++;

        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const scale = Math.min(MAX_WIDTH / img.width, MAX_HEIGHT / img.height, 1);
          const canvasWidth = Math.floor(img.width * scale);
          const canvasHeight = Math.floor(img.height * scale);

          const canvas = document.createElement('canvas');
          canvas.width = canvasWidth;
          canvas.height = canvasHeight;
          const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

          if (ctx) {
            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);

            // Generate base64 string
            const imageType = file.type;
            let base64String: string;
            if (imageType === 'image/jpeg') {
              base64String = canvas.toDataURL(imageType, 0.7); // 70% quality
            } else {
              base64String = canvas.toDataURL(imageType);
            }
            console.log("base64String-->", base64String)
            // Create new icon object
            const newIcon = {
              attachmentId: '',
              deleteAttachementStatus: false,
              imageType: imageType.split('/')[1],
              imageData: base64String,
            };
            newIcons.push(newIcon);
          } else {
            console.error('Failed to get 2D context for canvas');
            // Skip this file; no newIcon is added
          }

          // Update state when all files are processed
          if (processedCount === files.length) {
            setItemFiles((prevItemFiles: any[]) => [...(prevItemFiles || []), ...newIcons]);
            setPreviewImages((prevPreviewImages: string[]) => [
              ...(prevPreviewImages || []),
              ...newIcons.map((icon) => icon.imageData),
            ]);
          }
        };
      };
    });
  };

  const getIronPersonData = () => {
    let req = {
      roleId: '2',
    };
    callApi("POST", "userRoleGet.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setIronPerson(response.list);
          if (response.list.length != 0 && lead.ironPersonId != '0') {
            const selectedIronPerson = response.list.find((person: any) => person.id === lead.ironPersonId);
            setFormData((prevFormData) => ({
              ...prevFormData,
              assignedIroningPerson: selectedIronPerson ? selectedIronPerson.id : ""
            }));
          }
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getService = () => {
    let req = {
      page: '',
      limit: '',
      searchTerm: '',
    };
    callApi("POST", "serviceList.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setService(response["list"]);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getProduct = (id:any) => {
    let req = {
      page: '',
      limit: '',
      searchTerm: '',
      apartmentId:id,
    };
    callApi("POST", "productList.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setProducts(response["list"]);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getPaymentMethod = () => {
    let req = {
      page: '',
      limit: '',
      searchTerm: '',
    };
    callApi("POST", "paymentMethodList.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setPayment(response["list"]);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getDeliveryPersonData = () => {
    let req = {
      roleId: '3',
    };
    callApi("POST", "userRoleGet.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setDeliveryPerson(response.list);
          if (response.list.length != 0 && lead.deliveryPersonId != '0') {
            const selectedDeliveryPerson = response.list.find((person: any) => person.id === lead.deliveryPersonId);
            setFormData((prevFormData) => ({
              ...prevFormData,
              assignedDeliveryPerson: selectedDeliveryPerson ? selectedDeliveryPerson.id : ""
            }));
          }
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (lead?.status === "1") {
      setFormData((prevData) => ({ ...prevData, status: "2" }));
    } else if (lead?.status === "2") {
      setFormData((prevData) => ({ ...prevData, status: "3" }));
    } else if (lead?.status === "3") {
      setFormData((prevData) => ({ ...prevData, status: "4" }));
    } else if (lead?.status === "4") {
      setFormData((prevData) => ({ ...prevData, status: "5" }));
    }
    if (lead?.orders && lead.orders.length > 0) {
      setOrders(lead.orders);
    }
    console.log("lead deatilsh--->",lead);
    
    getDeliveryPersonData();
    getIronPersonData();
    getService();
    getProduct(lead.apartmentId);
    getPaymentMethod();
    if (lead?.attachment?.length > 0) {
      const newItemFiles = lead.attachment.map((att: any) => ({
        attachmentId: att.attachmentId,
        deleteAttachementStatus: false,
        imageType: 'image/png',
        imageData: att.imageData
      }));
      setItemFiles(newItemFiles);
      setPreviewImages(lead.attachment.map((att: any) => att.imageData));
    }
    console.log('lead', lead, previewImages);
    console.log("pdfLink", pdfLink)
  }, [lead, pdfLink]);

  if (!lead) {
    return <div>No lead data</div>;
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    console.log("event.target", event.target.value);

    const { id, value } = event.target;

    // Find the selected payment method from the list
    const selectedPayment:any = payment.find((product: any) => product.id === value);
    let PaymentTempleteId:any = "" ;

    if (selectedPayment) {
      console.log("Delivery Status Name:", selectedPayment?.deliveryStatus);
      PaymentTempleteId=selectedPayment?.deliveryStatus;
      console.log("PaymentTempleteId-->",PaymentTempleteId);
      
    }
    setFormData((prevData) => ({ ...prevData, [id]: value,PaymentTempleteId}));
    if (id == 'assignedDeliveryPerson') {
      setFormData((prevData) => ({ ...prevData, deliveryPersonId: value }));
    }
    if (id == 'assignedIroningPerson') {
      setFormData((prevData) => ({ ...prevData, ironPersonId: value }));
    }
    if (id == 'paymentMethod') {
      if (id === 'paymentMethod') {
        setSelectedPayment(value);
        const selectedPaymentMethod: any = payment.find((p: any) => p.id === value);
        if (selectedPaymentMethod) {
          setShowReceivedAmount(selectedPaymentMethod.deliveryStatus !== "5");
        }
      }
    }
  };

  const handleDateChange = (value: any, field: string) => {
    const formattedDate = `${value.getFullYear()}-${String(value.getMonth() + 1).padStart(2, '0')}-${String(value.getDate()).padStart(2, '0')}`;
    setFormData((prevData) => ({ ...prevData, [field]: formattedDate }));
  };

  const handleModalOpen = (image: any) => {
    setIsModalOpen(true);
    setSelectedImagePreview(image);
  }

  const handleFrontBrowseClick = () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    } console.log('Button clicked');
  };

  const handleItemInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    if (name === "productName") {
      const selectedProduct = products.find((product: any) => product.id === value);
      if (selectedProduct) {
        setOrders((prevOrders: any) =>
          prevOrders.map((order: any, i: any) =>
            i === index
              ? { ...order, productName: value, price: selectedProduct.price }
              : order
          )
        );
      }
    } else {
      setOrders((prevOrders) =>
        prevOrders.map((order, i) =>
          i === index ? { ...order, [name]: value } : order
        )
      );
    }
  };

  const addMoreItems = () => {
    setOrders([
      ...orders,
      { orderid: '', productName: '', price: '', quantity: '' }
    ]);
  };

  const removeItem = (index: number) => {
    setOrders(orders.filter((_, i) => i !== index));
  };

  const handleback = () => {
    let currentOrderStatus = parseInt(formData.orderStatus, 10);
    navigate(`/layout/orders/${currentOrderStatus}`);

  }
  const handleSubmit = (event: any) => {
    const form = event.target.form;
    if (form.checkValidity() === false) {
      console.log("Form validation failed.");
      return;
    }
    event.preventDefault();
    setLoading(true);
    console.log('Form Data:', formData);
    console.log("formData.PaymentTempleteId-->",formData.PaymentTempleteId);

    let currentOrderStatus = parseInt(formData.orderStatus, 10);
    let newOrderStatus = currentOrderStatus < 6 ? currentOrderStatus + 1 : currentOrderStatus;

    console.log('Form Data:orderStatus', formData.orderStatus);
    console.log("currentOrderStatus--->", currentOrderStatus);
    console.log("newOrderStatus--->", newOrderStatus);
    const items = formData.selectedTags.map((tagId) => ({
      tagId: tagId,
    }));


    let formattedPickupDate = formData.pickupDate ? new Date(formData.pickupDate).toISOString().slice(0, 10) : null;
    let formattedExpectedDeliveryDate = formData.expectedDeliveryDate ? new Date(formData.expectedDeliveryDate).toISOString().slice(0, 10) : null;
    let req = {
      id: formData.id,
      apartmentName: formData.apartmentId,
      mobileNo: formData.mobileNo,
      flatNo: formData.flatNo,
      pickupDate: formattedPickupDate,
      service: formData.service,
      expectedDeliveryDate: formattedExpectedDeliveryDate,
      assignedId: formData.assignedDeliveryPerson,
      assigntoIron: formData.assignedIroningPerson,
      category: formData.category,
      comments: formData.comment,
      attachment: itemFiles,
      orderStatus: newOrderStatus,
      items: items,
    } as {
      id: string;
      apartmentName: string;
      mobileNo: string;
      flatNo: string;
      pickupDate: string;
      service: string;
      expectedDeliveryDate: string;
      assignedId: string;
      assigntoIron: string;
      category: string;
      comments: string;
      attachment: any[];
      orderStatus: number;
      orders?: any[];
      receivedAmount?: string;
      paymentMethod?: string;
    };
    if (currentOrderStatus !== 0) {
      const filteredOrders = orders.filter(order =>
        order.orderid || order.productName || order.price || order.quantity
      );
      if (filteredOrders.length > 0) {
        req.orders = filteredOrders;
      }
    }
    if (currentOrderStatus == 1) {
      if (formData.service == '1') {
        req.orderStatus = 3;
      }
    }
    if (currentOrderStatus == 3) {
      const filteredOrders = orders.filter(order =>
        order.orderid || order.productName || order.price || order.quantity
      );
      if (filteredOrders.length === 0) {
        showError("Failed", 'Please fill the Order Item Details');
        setLoading(false);
        return;
      }
      if (itemFiles.length === 0) {
        showError("Failed", 'Please Upload images for the Order');
        setLoading(false);
        return;
      }
      req.orders = filteredOrders;
    }
    if (currentOrderStatus == 4) {
      if (formData.paymentMethod == '') {
        showError("Failed", 'Please fill the Payment Method Details');
        setLoading(false);
        return;
      }
      const selectedPaymentMethod: any = payment.find((p: any) => p.id === formData.paymentMethod);
      if (selectedPaymentMethod.deliveryStatus !== "5" && formData.receivedAmount == '0') {
        showError("Failed", 'Please fill the Received Amount Details');
        setLoading(false);
        return;
      }
      let receivedAmount = `${formData.receivedAmount}.00`
      if (selectedPaymentMethod.deliveryStatus !== "5" && receivedAmount != formData.totalPrice) {
        showError("Error", "The received amount must match the total price.");
        setLoading(false);
        return;
      }
      let selectedStatus: any = payment?.find((item: any) => item.id === formData.paymentMethod)
      req.receivedAmount = formData.receivedAmount;
      req.paymentMethod = formData.paymentMethod;
      if (selectedStatus) {
        req.orderStatus = selectedStatus.deliveryStatus;
      }
      uploadPdf();
    }

    if(formData.PaymentTempleteId=="5"){
      console.log("123456");
      
      handleGalloPayment();
    }

    callApi("POST", "leadUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success", response.message);
          setLoading(false);
          setTimeout(() => {
            navigate(`/layout/orders/${currentOrderStatus}`);
          }, 1000);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
      setLoading(false);
        console.log(err);
      });
      setLoading(false);
  };


  const handleSubmitUpdate = (event: any) => {
  
    setLoadingUpdate(true);
    console.log('Form Data:', formData);
    let currentOrderStatus = parseInt(formData.orderStatus, 10);
    console.log('Form Data:orderStatus', formData.orderStatus);
    console.log("currentOrderStatus--->", currentOrderStatus);
    const items = formData.selectedTags.map((tagId) => ({
      tagId: tagId,
    }));

    let formattedPickupDate = formData.pickupDate ? new Date(formData.pickupDate).toISOString().slice(0, 10) : null;
    let formattedExpectedDeliveryDate = formData.expectedDeliveryDate ? new Date(formData.expectedDeliveryDate).toISOString().slice(0, 10) : null;
    let req = {
      id: formData.id,
      apartmentName: formData.apartmentId,
      mobileNo: formData.mobileNo,
      flatNo: formData.flatNo,
      pickupDate: formattedPickupDate,
      service: formData.service,
      expectedDeliveryDate: formattedExpectedDeliveryDate,
      assignedId: formData.assignedDeliveryPerson,
      assigntoIron: formData.assignedIroningPerson,
      category: formData.category,
      comments: formData.comment,
      attachment: itemFiles,
      orderStatus: currentOrderStatus,
      items: items, 
      
    } as {
      id: string;
      apartmentName: string;
      mobileNo: string;
      flatNo: string;
      pickupDate: string;
      service: string;
      expectedDeliveryDate: string;
      assignedId: string;
      assigntoIron: string;
      category: string;
      comments: string;
      attachment: any[];
      orderStatus: number;
      orders?: any[];
      receivedAmount?: string;
      paymentMethod?: string;
    };
    if (currentOrderStatus !== 0) {
      const filteredOrders = orders.filter(order =>
        order.orderid || order.productName || order.price || order.quantity
      );
      if (filteredOrders.length > 0) {
        req.orders = filteredOrders;
      }
    }
    // if (currentOrderStatus == 1) {
    //   if (formData.service == '1') {
    //     req.orderStatus = 3;
    //   }
    // }
    // if (currentOrderStatus == 3) {
    //   const filteredOrders = orders.filter(order =>
    //     order.orderid || order.productName || order.price || order.quantity
    //   );
    //   // if (filteredOrders.length === 0) {
    //   //   showError("Failed", 'Please fill the Order Item Details');
    //   //   setLoadingUpdate(false);
    //   //   return;
    //   // }
    //   // if (itemFiles.length === 0) {
    //   //   showError("Failed", 'Please Upload images for the Order');
    //   //   setLoadingUpdate(false);
    //   //   return;
    //   // }
    //   req.orders = filteredOrders;
    // }
    if (currentOrderStatus == 4) {
      // if (formData.paymentMethod == '') {
      //   showError("Failed", 'Please fill the Payment Method Details');
      //   setLoadingUpdate(false);
      //   return;
      // }
      // const selectedPaymentMethod: any = payment.find((p: any) => p.id === formData.paymentMethod);
      // if (selectedPaymentMethod.deliveryStatus !== "5" && formData.receivedAmount == '0') {
      //   showError("Failed", 'Please fill the Received Amount Details');
      //   setLoadingUpdate(false);
      //   return;
      // }
      // let receivedAmount = `${formData.receivedAmount}.00`
      // if (selectedPaymentMethod.deliveryStatus !== "5" && receivedAmount != formData.totalPrice) {
      //   showError("Error", "The received amount must match the total price.");
      //   setLoadingUpdate(false);
      //   return;
      // }
      let selectedStatus: any = payment?.find((item: any) => item.id === formData.paymentMethod)
      req.receivedAmount = formData.receivedAmount;
      req.paymentMethod = formData.paymentMethod;
      if (selectedStatus) {
        req.orderStatus = selectedStatus.deliveryStatus;
      }
      uploadPdf();
    }

    if(formData.PaymentTempleteId=="5"){
      console.log("123456");

      handleGalloPayment();
    }

    console.log("updated req===>", req);
    callApi("POST", "leadUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success", response.message);
          setLoadingUpdate(false);
          setTimeout(() => {
            navigate(`/layout/orders/${currentOrderStatus}`);
          }, 1000);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateNot = (event: any) => {
  
    setLoadingNotIn(true);
    console.log('Form Data:', formData);
    let currentOrderStatus = parseInt(formData.orderStatus, 10);

    let req = {
      id: formData.id,
      orderStatus:currentOrderStatus
    } 
  
    console.log("notHomeStatusUpdate req===>", req);
    callApi("POST", "notHomeStatusUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success", response.message);
          setLoadingNotIn(false);
          setTimeout(() => {
            navigate(`/layout/orders/${currentOrderStatus}`);
          }, 1000);
        } else {
          showError("Failed", response.error);
          setLoadingNotIn(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const uploadPdf = () => {
    const pdfData = GenerateInvoicePDF(formData, orders, products);
    const link = document.createElement('a');
    link.href = pdfData;
    console.log(link, pdfData)
    let req = {
      leadId: formData.id,
      customer_id: formData.customerId,
      attachment: [{
        imageType: 'pdf',
        imageData: pdfData
      }],
    };
    callApi("POST", "invoice_create.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setPdfLink(response["pdf"]);
          if (response["pdf"]) {
            // handleGalloPayment();
          }
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleGalloPayment = async () => {
    let req = {
      id: formData.id,
    };
    callApi("POST", "paymentTemplate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        handlePaymentInvoice();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handlePaymentInvoice = async () => {
    let req = {
      id: formData.id,
    };
    callApi("POST", "whatsapppdf.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log('response', response)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const calculateTotal = () => {
    const total = orders.reduce((sum, item) => {
      const price = parseFloat(item.price) || 0;  // Convert price to number, default to 0 if invalid
      const quantity = parseInt(item.quantity) || 0;  // Convert quantity to number, default to 0 if invalid
      return sum + price * quantity;
    }, 0);
    return total;  // Return the raw number (we’ll format it later)
  };

  const calculateTotalItems = () => orders.length;

  return (
    <>
      <div className="addlead">
        {formData.orderStatus == "" ? (
          ""
        ) : (
          <div className="lead-title lead-form">
            <span>Edit Order</span>
{formData.notInhomeStatus =="" && (
  <>
     <Button style={{background:"red",color:"#ffffff"}} onClick={handleUpdateNot}>
            {loadingNotIn ? 'Updating....' : 'Not In Home'}
            </Button>
  </>
)}
         
          </div>
        )}
        <form>
<div className="leadformfields">
        <Form.Group controlId="formSelectedApartments" style={{ marginTop: '1%' }}>
          <Form.Label>Selected Tags</Form.Label>
          <div>
            {formData.selectedTags.map((apId: any) => {
              const apartment: any = tagsList.find((a: any) => a.value === apId);
              return apartment ? (
                <Badge key={apId} pill bg="primary" style={{ marginRight: '5px', marginBottom:"10px", fontSize:'15px',  }}>
                  {apartment.label}
                  <Button
                    variant="link"
                    size="sm"
                    style={{ color: 'white', textDecoration: 'none', padding: '5 5px' ,marginTop:"-4px"}}
                    onClick={() => removeTags(apId)}
                  >
                    x
                  </Button>
                </Badge>
              ) : null;
            })}
          </div>
        </Form.Group>

        <Form.Group controlId="formApartmentSelect" style={{ marginTop: '1%' }}>
          <Form.Label>Add Tags</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => addTags(e.target.value)}
          >
            <option value="">Select an Tags</option>
            {tagsList.map((ap: any) => (
              <option key={ap.value} value={ap.value}>
                {ap.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        </div>
        
          <div className="lead-form">
            {/* Flat Name */}
            <div className="leadformfields">
              <label htmlFor="apartmentName">Apartment Name<span style={{ color: 'red' }}>*</span></label>
              <input
                className="addleadinput"
                type="text"
                id="apartmentName"
                value={formData.apartmentName}
                onChange={handleInputChange}
                maxLength={50}
                disabled
              />
            </div>

            {/* Flat No */}
            <div className="leadformfields">
              <label htmlFor="flatNo">Flat No<span style={{ color: 'red' }}>*</span></label>
              <input
                className="addleadinput"
                type="text"
                id="flatNo"
                value={formData.flatNo}
                onChange={handleInputChange}
                maxLength={10}
                disabled
              />
            </div>

            {/* Phone Number */}
            {roleType === '1' && (
              <div className="leadformfields">
                <label htmlFor="mobileNo">Phone Number<span style={{ color: 'red' }}>*</span></label>
                <input
                  className="addleadinput"
                  type="tel"
                  id="mobileNo"
                  value={formData.mobileNo}
                  onChange={handleInputChange}
                  maxLength={10}
                  disabled
                />
              </div>
            )}

            {/* Pickup Date */}
            <div className="leadformfields">
              <label htmlFor="pickupDate">PickUp Date</label>
              <DatePicker
                format="dd/MM/yyyy"
                oneTap
                cleanable={false}
                onChange={(value) => handleDateChange(value, "pickupDate")}
                value={formData.formPickupDate ? new Date(formData.formPickupDate) : null}
                disabledDate={(date) => date ? date < new Date(new Date().setHours(0, 0, 0, 0)) : false}
              />
            </div>

            {/* Pickup Date */}
            {/* <div className="leadformfields">
              <label htmlFor="expectedDeliveryDate">Expected Delivery Date</label>
              <DatePicker
                format="dd/MM/yyyy"
                oneTap
                cleanable={false}
                onChange={(value) => handleDateChange(value, "expectedDeliveryDate")}
                value={formData.expectedDeliveryDate ? new Date(formData.expectedDeliveryDate) : null}
                disabledDate={(date) => date ? date < new Date(new Date().setHours(0, 0, 0, 0)) : false}

              />
            </div> */}

            {/* Service Required */}
            <div className="leadformfields">
              <label htmlFor="service">Service Required<span style={{ color: 'red' }}>*</span></label>
              <select
                id="service"
                className="addleadinput"
                value={formData.service}
                onChange={handleInputChange}
                disabled={formData.orderStatus == '6'}
              >
                <option value=""></option>
                {service.map((service: any) => (
                  <option key={service.id} value={service.id}>
                    {service.service}
                  </option>
                ))}
              </select>
            </div>

            {/* Assigned Delivery Person */}
            <div className="leadformfields">
              <label htmlFor="assignedDeliveryPerson">Assigned Delivery Person<span style={{ color: 'red' }}>*</span></label>
              <select
                id="assignedDeliveryPerson"
                className="addleadinput"
                value={formData.deliveryPersonId}
                onChange={handleInputChange}
                disabled={formData.orderStatus == '6'}
              >
                <option value=""></option>
                {deliveryPerson.map((person) => (
                  <option key={person.id} value={person.id}>
                    {person.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Assigned Ironing Person */}
            <div className="leadformfields">
              <label htmlFor="assignedIroningPerson">Assigned Ironing Person<span style={{ color: 'red' }}>*</span></label>
              <select
                id="assignedIroningPerson"
                className="addleadinput"
                value={formData.ironPersonId}
                onChange={handleInputChange}
                disabled={formData.orderStatus == '6'}
              >
                <option value=""></option>
                {IronPerson.map((person) => (
                  <option key={person.id} value={person.id}>
                    {person.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="leadformfields">
              <label htmlFor="category">Urgency<span style={{ color: 'red' }}>*</span></label>
              <select disabled={formData.orderStatus == '6'} id="category" className="addleadinput" value={formData.category} onChange={handleInputChange}>
                <option value="hot">Hot</option>
                <option value="warm">Warm</option>
                <option value="cold">Cold</option>
              </select>
            </div>

            {/* Status */}
            {(parseInt(formData.orderStatus) < 4) && (<div className="leadformfields">
              <label htmlFor="status">Status<span style={{ color: 'red' }}>*</span></label>
              <select id="status" className="addleadinput" value={formData.status} onChange={handleInputChange} disabled={formData.orderStatus == '6'}>
                <option value="0" disabled={lead.status !== "0"}>Pickup/Delivery Requests</option>
                <option value="1" disabled={lead.status !== "0"}>To Be Picked Up</option>
                <option value="2" disabled={lead.status !== "1"}>Picked Up</option>
                <option value="3" disabled={lead.status !== "2"}>Processing</option>
                <option value="4" disabled={lead.status !== "3"}>Ready for Delivery</option>
                <option value="5" disabled={lead.status !== "4"}>Payment Pending</option>
                <option value="6" disabled={lead.status !== "5"}>Delivered</option>
              </select>
            </div>
            )}

            <div className="leadformfields">
              <label htmlFor="image">Item Images
                {(formData.orderStatus != '0') && (<span style={{ color: 'red' }}>*</span>)}</label>
              {(previewImages.length == 0) ? (
                <div className="file-upload" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    multiple
                    capture="environment"
                    onChange={handleFileChange}
                    required
                  />
                </div>) : (
                <div className="file-uploaded" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', height: 'auto' }}>
                  {previewImages?.map((image, index) => (
                    <div key={index} style={{ margin: '10px' }}>
                      <img
                        src={image}
                        alt={`Preview ${index}`}
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'contain',
                          border: '1px solid #ddd',
                          borderRadius: '4px',
                        }}
                        onClick={() => handleModalOpen(image)}
                      />
                    </div>
                  ))}
                  {formData.orderStatus != '6' && (
                    <img src={EditDesignIcon} style={{ width: '40px', marginLeft: '10px' }} alt="icon" onClick={handleFrontBrowseClick} />)}
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    multiple
                    capture="environment"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </div>
              )}
            </div>

            {formData.orderStatus != '0' && (
              <div className="leadformfields">
                <label htmlFor="image">
                  Order Items
                  {(formData.orderStatus != '1' && formData.orderStatus != '2') && (
                    <span style={{ color: 'red' }}>*</span>
                  )}
                </label>
                <div className="add-item">
                  {orders.map((item, index) => (
                    <Row key={index} style={{ marginTop: '2%' }}>
                      <Col sm={4}>
                        <div style={{ position: 'relative' }}>
                          <Form.Control
                            as="select"
                            name="productName"
                            value={item.productName}
                            className="input eye-focus"
                            onChange={(e) => handleItemInputChange(index, e)}
                            disabled={formData.orderStatus == '6'}
                          >
                            <option value="" disabled>Select Product</option>
                            {products.map((product) => (
                              <option key={product.id} value={product.id}>
                                {product.productName}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                      </Col>
                      <Col sm={3} className="mobile-item">
                        <Form.Control
                          type="text"
                          style={{ height: '50px' }}
                          name="price"
                          value={item.price}
                          className="input"
                          placeholder="Price"
                          disabled
                        />
                      </Col>
                      <Col sm={3} className="mobile-item">
                        <div style={{ display: 'flex', height: '50px' }}>
                          <Form.Control
                            type="text"
                            name="quantity"
                            value={item.quantity}
                            className="input"
                            placeholder="Quantity"
                            maxLength={4}  // Changed from 3 to 4
                            disabled={formData.orderStatus === '6'}
                            onKeyPress={(e) => {
                              const isNumber = /^[1-9]$/.test(e.key) || (item.quantity && /^[0-9]$/.test(e.key));
                              if (!isNumber) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (/^(0|[1-9][0-9]{0,3})$/.test(newValue) || newValue === '') {
                                handleItemInputChange(index, e);
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm={2} className="mobile-item">
                        <div style={{ display: 'flex' }}>
                          {index > 0 && formData.orderStatus != '6' && (
                            <img
                              style={{ width: '28px' }}
                              src={RemoveItem}
                              onClick={() => removeItem(index)}
                              alt="icon"
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  ))}
                  {formData.orderStatus < '5' && (
                    <div className="form-actions">
                      <div className="add-more-btn" onClick={addMoreItems}>
                        + Add more
                      </div>
                    </div>
                  )}
                  {/* Total Field */}
                  <div style={{ marginTop: '2%', textAlign: 'right' }}>
                    <strong>Total: </strong>
                    <Form.Control
                      type="text"
                      value={calculateTotal() === 0 ? '' : calculateTotal().toFixed(2)}
                      className="input"
                      style={{
                        display: 'inline-block',
                        width: 'auto',
                        height: '50px',
                        backgroundColor: '#f5f5f5',
                        color: '#6c757d'
                      }}
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}

            {(formData.orderStatus === '4' || formData.orderStatus === '5' || formData.orderStatus === '6') && (
              <>
                <div className="leadformfields" style={{ width: '12%' }}>
                  <label htmlFor="totalItems">Total Items<span style={{ color: 'red' }}>*</span></label>
                  <input
                    className="addleadinput"
                    type="text"
                    id="totalItems"
                    value={calculateTotalItems()}
                    onChange={handleInputChange}
                    disabled />
                </div>
                <div className="leadformfields" style={{ width: '12%' }}>
                  <label htmlFor="totalPrice">Total Price<span style={{ color: 'red' }}>*</span></label>
                  <input
                    className="addleadinput"
                    type="text"
                    id="totalPrice"
                    value={calculateTotal() === 0 ? '' : calculateTotal().toFixed(2)}
                    onChange={handleInputChange}
                    disabled />
                </div>
              </>
            )}

            {(formData.orderStatus === '4' || formData.orderStatus === '5' || formData.orderStatus === '6') && (
              <>
                <div className="leadformfields" style={{ width: '16%' }}>
                  <label htmlFor="paymentMethod">Payment Method<span style={{ color: 'red' }}>*</span></label>
                  <select id="paymentMethod" className="addleadinput" value={formData.paymentMethod} onChange={handleInputChange} disabled={formData.orderStatus == '6'}>
                    <option value="" disabled>Select a payment method</option>
                    {payment.map((product: any) => (
                      <option key={product.id} value={product.id}>
                        {product.paymentType}
                      </option>
                    ))}
                  </select>
                </div>
                {showReceivedAmount && (
                  <div className="leadformfields" style={{ width: '12%' }}>
                    <label htmlFor="receivedAmount">Received Amount<span style={{ color: 'red' }}>*</span></label>
                    <input
                      className="addleadinput"
                      type="tel"
                      id="receivedAmount"
                      value={formData.receivedAmount}
                      onChange={handleInputChange}
                      maxLength={10}
                      disabled={formData.orderStatus == '6'}
                    />
                  </div>)}
              </>
            )}
            <div className="leadformfields">
              <label htmlFor="comment">Comments</label>
              <textarea disabled={formData.orderStatus == '6'} onChange={handleInputChange} id="comment" maxLength={250} value={formData.comment} />
            </div>

          </div>

          {formData.orderStatus != '6' && <div className="d-flex justify-content-around">
            <button className="newlead-btn" onClick={handleback} style={{ opacity: '1' }} disabled={loading}>
              Back
            </button>
            {roleType == "1" && (
              <>
                {formData.orderStatus < "5" && (
                  <button className="newlead-btn" type="button" onClick={handleSubmitUpdate} style={{ opacity: '1' }} disabled={loading}>
                    {loadingupdate ? 'Updating....' : 'Update'}
                  </button>
                )}
              </>
            )}
            <button className="newlead-btn" type="submit" onClick={handleSubmit} style={{ opacity: '1' }} disabled={loading}>
              {loading ? 'Submitting....' : 'Submit'}
            </button>
          </div>
          }
        </form>
        <Modal size="lg" open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Modal.Header>
            <Modal.Title>Preview Image Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img style={{ width: '100%', height: '100%' }} src={selectedImagePreview} alt="image" />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setIsModalOpen(false)} >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default StatusFormPage;
