import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Badge } from 'react-bootstrap';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";
import { log } from 'node:console';

const ProductUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const [loading, setLoading] = useState(false);

  const [apartments, setApartment] = useState([]);

  const [formData, setFormData] = useState<{
    id: string;
    productName: string;
    price: string;
    selectedApartments: string[];
  }>({
    id: '',
    productName: '',
    price: '',
    selectedApartments: [],
  });
  useEffect(() => {
    if (user) {
      getProduct();
    }
    getService();
    console.log(formData)
  }, []);

  const getService = () => {
    callApi("GET", "apartmentDropdown.php")
      .then((res) => res.data)
      .then((response: any) => {
        if (response) {
          setApartment(response["data"]);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const getProduct = () => {
    let req = {
      id: user
    };
    callApi("POST", "productGetId.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response) {
          const selectedApartments = response.items.map((item: any) => item.apartmentId);
          
          setFormData({
            id: response.id,
            productName: response.productName,
            price: response.price,
            selectedApartments: selectedApartments,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addProduct = () => {
    setLoading(true);
    const trimmedProductName = formData.productName.trim();
    const trimmedProductPrice = formData.price.trim();
  
    if (trimmedProductName && trimmedProductPrice) {
      // Create the items array with selected apartment IDs
      const items = formData.selectedApartments.map((apartmentId) => ({
        apartmentId: apartmentId,
      }));
  
      let req = {
        productName: trimmedProductName,
        price: trimmedProductPrice,
        items: items, // Include the items array with apartment IDs
      };
  
      callApi("POST", "productInsert.php", req)
        .then((res) => res.data)
        .then((response: any) => {
          console.log("response", response);
          if (response["success"] === true) {
            showSuccess("Success!", "Product created successfully!");
            setTimeout(() => {
              navigate("/layout/product-management");
            }, 1000);
            setLoading(false);
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      showError("Failed", "Empty Space Not Allowed");
    }
  };

  const updateProduct = () => {
    setLoading(true);

    const items = formData.selectedApartments.map((apartmentId) => ({
      apartmentId: apartmentId,
    }));


    let req = {
      id: formData.id,
      productName: formData.productName,
      price: formData.price,
      items: items,
    };
    callApi("POST", "productUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Product updated successfully!");
          setTimeout(() => {
            navigate("/layout/product-management");
          }, 1000);
          setLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (user) {
      updateProduct();
    } else {
      addProduct();
      console.log(formData)
    }
  };

  const addApartment = (apId: string) => {
    if (apId && !formData.selectedApartments.includes(apId)) {
      setFormData((prev) => ({
        ...prev,
        selectedApartments: [...prev.selectedApartments, apId],
      }));
      console.log("selectedApartments===>", formData.selectedApartments);
    }
  };

  const removeApartment = (apId: any) => {
    setFormData(prev => ({
      ...prev,
      selectedApartments: prev.selectedApartments.filter(id => id !== apId),
    }));
  };



  return (
    <div style={{ margin: '2% 4% 4%' }}>
      <h5>{user ? 'Edit Product' : 'Add New Product'}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formProductName" style={{ marginTop: '1%' }}>
          <Form.Label>Product Name<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
            minLength={2}
            maxLength={60}

            required
          />
        </Form.Group>
        <Form.Group controlId="formProductPrice" style={{ marginTop: '1%' }}>
          <Form.Label>Product Price<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="price"
            value={formData.price}
            onKeyPress={(e) => {
              const isNumber = /^[1-9]$/.test(e.key) || (formData.price && /^[0-9]$/.test(e.key));
              if (!isNumber) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              if (/^(0|[1-9][0-9]*)$/.test(newValue) || newValue === "") {
                handleChange(e);
              }
            }}
            required
            minLength={1}
            maxLength={5}
          />

        </Form.Group>


        <Form.Group controlId="formSelectedApartments" style={{ marginTop: '1%' }}>
          <Form.Label>Selected Apartments</Form.Label>
          <div>
            {formData.selectedApartments.map((apId: any) => {
              const apartment: any = apartments.find((a: any) => a.value === apId);
              return apartment ? (
                <Badge key={apId} pill bg="primary" style={{ marginRight: '5px',fontSize:'16px',  }}>
                  {apartment.label}
                  <Button
                    variant="link"
                    size="sm"
                    style={{ color: 'white', textDecoration: 'none', padding: '5 5px' }}
                    onClick={() => removeApartment(apId)}
                  >
                    x
                  </Button>
                </Badge>
              ) : null;
            })}
          </div>
        </Form.Group>

        <Form.Group controlId="formApartmentSelect" style={{ marginTop: '1%' }}>
          <Form.Label>Add Apartment</Form.Label>
          <Form.Control
            as="select"
            
            onChange={(e) => addApartment(e.target.value)}
          >
            <option value="">Select an apartment</option>
            {apartments.map((ap: any) => (
              <option key={ap.value} value={ap.value}>
                {ap.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>



        <Button style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', marginTop: '10%' }} type="submit" disabled={loading}>
          {user ? 'Update Product' : 'Add Product'}
        </Button>
      </Form>
    </div>
  );
};

export default ProductUpdate;
